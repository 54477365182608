<template>
    <!-- 区域总监 -->
    <div class="regionBox">
        <!-- 职位要求 -->
        <div class="ppbBox">
            <!-- 顶部大图 -->
            <div class="pcTopImg">
                <a :href="getAdvData(35).children[0].url" target="_blank" rel="noopener noreferrer">
                    <img v-if="getAdvData(35).children" :src="getAdvData(35).children[0].image" />
                </a>
            </div>
            <!-- 主要内容 -->
            <div class="ppbContent" v-if="currentObj.status">
                <!-- 左边主体 -->
                <div class="pcLeftBox">
                    <div class="editor-p" v-html="currentObj.details"></div>
                    <!-- 投递简历按钮 -->
                    <div class="deliveryBtn" onclick="document.querySelector('#file>div').click()" v-loading="loading">
                        <i class="iconfont icon-youxiang"></i>
                        <p>投递简历</p>
                        <el-upload
                            id="file"
                            name="thumbnail"
                            :action="upLoadUrl + '/api/Project/upload'"
                            :on-success="success"
                            :before-upload="beforeUpload"
                            :on-error="error"
                            :file-list="fileList"
                            :show-file-list="false"
                            :limit="1"
                            accept=".doc,.docx,.pdf,.xls,.xlsx"
                            style="display: none"
                            :disabled="loading"
                        >
                            上传
                        </el-upload>
                    </div>
                </div>
                <!-- 其他岗位盒子 -->
                <div class="pcrBox">
                    <div class="pcrTop">相关职位</div>
                    <div class="pcrList">
                        <ul>
                            <template v-for="el in idList">
                                <li
                                    v-if="el.status"
                                    class="pcrLi"
                                    :style="id == el.id ? 'color: #7781f1' : ''"
                                    @click="goDataPage(el.id)"
                                    :key="el.id"
                                >
                                    {{ el.title }}
                                </li>
                            </template>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import QueryString from "qs";
export default {
    name: "Region",
    inject: ["getAdvData"],
    data() {
        return {
            upLoadUrl: axios.defaults.baseURL,
            // upLoadUrl: "https://vuapi.vu818.com",
            loading: false,
            fileList: [],
            // 当前页的id
            id: null,
            idList: [],
        };
    },
    async created() {
        this.id = this.$route.query.id;
        this.idList = await this.getData();
    },
    methods: {
        goDataPage(id) {
            if (this.id == id) return;
            this.id = id;
            // switch (i) {
            //     case 1:
            //         this.$router.push("/region");
            //         break;
            //     case 2:
            //         this.$router.push("/sale");
            //         break;
            //     case 3:
            //         this.$router.push("/seniors");
            //         break;
            //     case 4:
            //         this.$router.push("/repsale");
            //         break;
            //     case 5:
            //         this.$router.push("/assistant");
            //         break;
            // }
        },
        async getData() {
            let { data } = await axios.get("/api/recruit/brother", { params: { id: this.id } });
            return data;
        },
        // 上传前
        beforeUpload(file) {
            this.loading = true;
            // 上传限制
            const xz = file.size / 1024 / 1024 < 30;
            if (!xz) {
                this.$message.error("上传文件大小不能超过 30MB!");
                return false;
            }
            return true;
        },
        // 失败
        error(err, file, fileList) {
            this.$message.error(err);
        },
        // 成功
        async success(url, file) {
            let annex = axios.defaults.baseURL + url;
            let { data } = await axios.post(
                "/api/mail/add",
                QueryString.stringify({
                    annex,
                    recruit_id: "",
                    channel: "投递简历",
                })
            );
            if (data.code !== 200) {
                this.$message.error(data.msg);
            } else {
                this.$message.success("您的简历已投递！请耐心等待我们的回复");
            }
            this.loading = false;
            this.fileList = [];
        },
    },
    computed: {
        currentObj() {
            let obj = this.idList.find(e => e.id == this.id);
            return obj ? obj : {};
        },
    },
};
</script>

<style lang="less" scoped>
.regionBox {
    width: 100%;
    text-align: left;
    .ppbBox {
        width: 100%;
        // 顶部图片
        .pcTopImg {
            width: 100%;
            // height: 400px;
            // background: url("http://iv.okvu.cn/vugw/img/images/bobanner.jpg") no-repeat top center;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        // 主要内容
        .ppbContent {
            width: 1280px;
            min-height: 800px;
            margin: 0 auto 20px;
            margin-top: 50px;
            position: relative;
            display: flex;
            // 左边主体
            .pcLeftBox {
                height: 100%;
                flex: 1;
                // 投递简历按钮
                .deliveryBtn {
                    width: 200px;
                    height: 50px;
                    float: left;
                    cursor: pointer;
                    border-radius: 10px;
                    line-height: 50px;
                    margin-top: 50px;
                    text-align: center;
                    color: white;
                    background: linear-gradient(to right, #7781f1, #7781f1e0);
                    transition: 0.5s;
                    .iconfont {
                        width: 50px;
                        height: 50px;
                        font-size: 25px;
                        float: left;
                        margin-left: 30px;
                    }
                    p {
                        width: 65px;
                        height: 50px;
                        float: left;
                    }
                }
                .deliveryBtn:hover {
                    transform: scale(1.1);
                }
            }
            // 右边其他岗位
            .pcrBox {
                width: 300px;
                height: 300px;
                margin: 0 auto;
                box-shadow: 1px 1px 10px 1px gainsboro;
                border-radius: 20px;
                // position: absolute;
                // top: 0;
                // right: 10px;
                // 相关职位
                .pcrTop {
                    width: 90%;
                    height: 50px;
                    line-height: 50px;
                    font-size: 20px;
                    font-weight: 900;
                    margin: 0 auto;
                    border-bottom: 1px solid gainsboro;
                }
                .pcrList {
                    width: 90%;
                    height: 200px;
                    margin: 0 auto;
                    margin-top: 15px;
                    ul {
                        width: 100%;
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        .pcrLi {
                            width: 100%;
                            height: 35px;
                            margin-left: 20px;
                            line-height: 35px;
                            list-style: square;
                            cursor: pointer;
                        }
                        .pcrLi:hover {
                            color: #7781f1;
                        }
                    }
                }
            }
        }
    }
}
</style>
